<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/well-groups">Well Groups</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">{{ wellGroup?.WellGroupName }}</li>
    </ol>
</nav>

<div class="container">
    <app-alert-display></app-alert-display>

    <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" [animation]="false" (shown)="resizeWindow()" class="nav-tabs nav-fill">
        <ng-container ngbNavItem>
            <a ngbNavLink><h5>Well Overview</h5></a>
            <ng-template ngbNavContent>
                <div class="row mt-4">
                    <div class="col-md-6">
                        <div class="card" *ngIf="wellGroup">
                            <div class="card-header">
                                Well Group
                                <div class="float-end">
                                    <a class="btn btn-zybach btn-sm" routerLink="edit"> <span class="fas fa-edit"></span> Edit </a>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 label fw-bold">Well Group Name:</div>
                                    <div class="col-sm-6">{{ wellGroup.WellGroupName }}</div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-sm-6 label fw-bold">Wells:</div>
                                    <div class="col-sm-6">
                                        <div *ngFor="let wellGroupWell of wellGroup.WellGroupWells">
                                            <a routerLink="/wells/{{ wellGroupWell.WellID }}">{{ wellGroupWell.WellRegistrationID }}</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-sm-6 label fw-bold">Sensors:</div>
                                    <div class="col-sm-6" *ngIf="wellGroup.Sensors?.length > 0">
                                        <div *ngFor="let sensor of wellGroup.Sensors">
                                            <a routerLink="/sensors/{{ sensor.SensorID }}">{{ sensor.SensorName }}</a>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" *ngIf="wellGroup.Sensors?.length == 0">None</div>
                                </div>

                                <div class="row mt-2" *ngIf="wellGroup.PrimaryWell">
                                    <div class="col-sm-6 label fw-bold">Primary Well for Water Level Reporting:</div>
                                    <div class="col-sm-6">
                                        <a routerLink="/wells/{{ wellGroup.PrimaryWell.WellID }}">{{ wellGroup.PrimaryWell.WellRegistrationID }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">Location</div>

                            <div class="card-body">
                                <div #mapContainer [id]="mapID" style="height: 400px"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container ngbNavItem>
            <a ngbNavLink><h5>Water Levels</h5></a>

            <ng-template ngbNavContent *ngIf="wellGroup">
                <zybach-well-group-water-levels-tab [wellGroup]="wellGroup"></zybach-well-group-water-levels-tab>
            </ng-template>
        </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
