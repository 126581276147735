<header-nav></header-nav>
<div class="mh-auto min-vh-100 page-content">
    <router-outlet></router-outlet>
</div>
<footer class="footer mt-5">
    <div class="container">
        <hr class="footer-hr w-70" />

        <br />
        <div class="row pb-3">
            <div class="col no-gutters text-end">
                <a href="https://olsson.com/" alt="Olsson Associates" target="_blank">
                    <img src="assets/main/logos/olsson.png" style="height: 55px" />
                </a>
            </div>
            <div class="col no-gutters text-center">
                <a href="https://tpnrd.org/" alt="Twin Platte Natural Resources District" target="_blank" class="mx-4">
                    <img src="assets/main/logos/logo_round.png" style="height: 75px" />
                </a>
            </div>
            <div class="col no-gutters text-start">
                <a href="https://sitkatech.com/" alt="ESA Sitka" target="_blank">
                    <img src="assets/main/logos/ESA-Sitka_Logo.png" style="height: 65px" />
                </a>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col text-center mb-2 footer-text">
                This program is free software; you can redistribute it and/or modify it under the terms of the
                <a class="footer-link" href="https://www.gnu.org/licenses/agpl-3.0.html">GNU Affero General Public License</a>. Source code is available on
                <a class="footer-link" href="https://github.com/sitkatech/zybach/">GitHub</a>. Developed and Maintained by
                <a class="footer-link" href="https://sitkatech.com/">ESA Sitka</a>.
            </div>
        </div>
    </div>
</footer>
