<div class="breadcrumb-container">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-3">
            <li class="breadcrumb-item">
                <a routerLink="/">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Prism Monthly Syncs</li>
        </ol>
    </nav>
</div>
<div class="container mt-sm-4">
    <app-alert-display></app-alert-display>
    <ng-container *ngIf="!loadingPage; else loadingBlock">
        <div class="row mb-2">
            <div class="col">
                <h2 class="d-inline-block">Prism Integration</h2>
                <!-- 
                <div class="mt-2">
                    <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
                </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-4">
                                <div class="control-label mb-2">
                                    <label>Selected Year</label>
                                </div>

                                <div class="text-start mb-2">
                                    <select name="selectedYear" class="form-control" [ngModel]="selectedYear" (ngModelChange)="selectedYearChanged($event)" required>
                                        <option *ngFor="let allowedYear of allowedYears" [value]="allowedYear">
                                            {{ allowedYear }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="control-label mb-2">
                                    <label>Selected Data Type</label>
                                </div>
                                <div class="text-start mb-2">
                                    <select name="Selected Data Type" class="form-control" [ngModel]="selectedDataType" (ngModelChange)="selectedDataTypeChanged($event)" required>
                                        <option *ngFor="let allowedDataType of allowedDataTypes" [value]="allowedDataType">
                                            {{ allowedDataType }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="download-button-wrapper" *ngIf="showDownloadButtons">
                                    <button
                                        class="btn btn-primary btn-md"
                                        style="cursor: pointer"
                                        (click)="getRunoffData('json')"
                                        ngbTooltip="Download Runoff Data as JSON"
                                        [placement]="['bottom', 'right', 'left', 'auto']">
                                        <i class="fas fa-download"></i> Download {{ selectedYear }} Runoff Data as JSON
                                    </button>
                                    <button
                                        class="btn btn-primary btn-md"
                                        style="cursor: pointer; margin-left: 1rem"
                                        (click)="getRunoffData('csv')"
                                        ngbTooltip="Download Runoff Data as JSON"
                                        [placement]="['bottom', 'right', 'left', 'auto']">
                                        <i class="fas fa-download"></i> Download {{ selectedYear }} Runoff Data as CSV
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="col-auto ms-auto">
                                <fresca-clear-grid-filters-button [grid]="prismMonthlySyncGrid" classList="me-2"></fresca-clear-grid-filters-button>
                                <button class="btn btn-secondary btn-md" style="cursor: pointer;" (click)="exportToCsv()"
                                    ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']"><i class="fas fa-download"></i>
                                </button>
                            </div> -->
                        </div>

                        <ag-grid-angular
                            #prismMonthlySyncGrid
                            style="width: 100%; height: 800px"
                            class="ag-theme-balham"
                            [rowData]="prismMonthlySyncs"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef"
                            [pagination]="true"
                            [paginationPageSize]="100"
                            [suppressMenuHide]="true">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loadingBlock>
    <div class="text-center"><i class="fa fa-spinner loading-spinner"></i> Loading</div>
</ng-template>
