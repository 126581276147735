<div class="container navCaretMargin">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">Well Group List</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-auto ms-auto">
            <fresca-clear-grid-filters-button [grid]="wellGroupsGrid" class="me-2"></fresca-clear-grid-filters-button>

            <button
                class="btn btn-secondary btn-md me-2"
                style="cursor: pointer"
                (click)="exportToCsv()"
                ngbTooltip="Download as CSV"
                [placement]="['bottom', 'right', 'left', 'auto']">
                <i class="fas fa-download"></i>
            </button>

            <a class="btn btn-primary btn-md" routerLink="new">New Well Group <span class="fas fa-plus"></span></a>
        </div>
    </div>

    <ag-grid-angular
        #wellGroupsGrid
        style="width: 100%; height: 500px"
        class="ag-theme-balham mt-2"
        [rowData]="wellGroups"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [paginationPageSize]="100"
        (gridReady)="onGridReady()"
        (cellClicked)="onCellClicked($event)">
    </ag-grid-angular>
</div>
