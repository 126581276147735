<form #annualRecordForm="ngForm">
    <div class="row col-12">
        <div class="row col-6 mt-4">
            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>Annual Renewal Status: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select
                    [(ngModel)]="model.ChemigationPermitAnnualRecordStatusID"
                    [items]="annualRecordStatuses"
                    bindLabel="ChemigationPermitAnnualRecordStatusDisplayName"
                    bindValue="ChemigationPermitAnnualRecordStatusID"
                    placeholder="Choose One"
                    name="ChemigationPermitAnnualRecordStatusID"
                    required>
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>Fee Type: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select
                    [(ngModel)]="model.ChemigationPermitAnnualRecordFeeTypeID"
                    [items]="feeTypes"
                    bindLabel="ChemigationPermitAnnualRecordFeeTypeDisplayName"
                    bindValue="ChemigationPermitAnnualRecordFeeTypeID"
                    placeholder="Choose One"
                    name="ChemigationPermitAnnualRecordFeeTypeID"
                    required>
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>Township-Range-Section: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="TownshipRangeSection" placeholder="Township-Range-Section" required [(ngModel)]="model.TownshipRangeSection" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Pivot Name: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="PivotName" placeholder="Pivot Name" [(ngModel)]="model.PivotName" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>Injection Unit Type: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select
                    [(ngModel)]="model.ChemigationInjectionUnitTypeID"
                    [items]="injectionUnitTypes"
                    bindLabel="ChemigationInjectionUnitTypeDisplayName"
                    bindValue="ChemigationInjectionUnitTypeID"
                    placeholder="Choose One"
                    name="ChemigationInjectionUnitTypeID"
                    required>
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Date Received: </strong></label>
            </div>
            <div class="col-6 text-start mb-2 form-group">
                <div class="input-group">
                    <input class="form-control" name="dp1" placeholder="yyyy-mm-dd" [(ngModel)]="model.DateReceived" ngbDatepicker #d1="ngbDatepicker" />
                    <div class="input-group-append">
                        <button class="btn btn-zybach far fa-calendar input-group-append" (click)="d1.toggle()" type="button"></button>
                    </div>
                </div>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Date Paid: </strong></label>
            </div>
            <div class="col-6 text-start mb-2 form-group">
                <div class="input-group">
                    <input class="form-control" name="dp2" placeholder="yyyy-mm-dd" [(ngModel)]="model.DatePaid" ngbDatepicker #d2="ngbDatepicker" />
                    <div class="input-group-append">
                        <button class="btn btn-zybach far fa-calendar" (click)="d2.toggle()" type="button"></button>
                    </div>
                </div>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Date Approved: </strong></label>
            </div>
            <div class="col-6 text-start mb-2 form-group">
                <div class="input-group">
                    <input class="form-control" name="dp3" placeholder="yyyy-mm-dd" [(ngModel)]="model.DateApproved" ngbDatepicker #d3="ngbDatepicker" />
                    <div class="input-group-append">
                        <button class="btn btn-zybach far fa-calendar" (click)="d3.toggle()" type="button"></button>
                    </div>
                </div>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Notes: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <textarea class="form-control" name="AnnualNotes" placeholder="Notes" [(ngModel)]="model.AnnualNotes"> </textarea>
            </div>
        </div>

        <div class="row col-6 mt-4">
            <div class="col-6 control-label text-end mb-2">
                <label><strong>Applicant Company: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantCompany" placeholder="Company" [(ngModel)]="model.ApplicantCompany" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Applicant First Name: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantFirstName" placeholder="First Name" [(ngModel)]="model.ApplicantFirstName" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Applicant Last Name: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantLastName" placeholder="Last Name" [(ngModel)]="model.ApplicantLastName" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>Address: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantMailingAddress" placeholder="Street Address" required [(ngModel)]="model.ApplicantMailingAddress" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>City: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantCity" placeholder="City" required [(ngModel)]="model.ApplicantCity" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>State: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <ng-select [(ngModel)]="model.ApplicantState" [items]="states | keyvalue" bindLabel="key" bindValue="value" placeholder="Choose One" name="ApplicantState" required>
                </ng-select>
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label class="required"><strong>Zip: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantZipCode" placeholder="Zip Code" required [(ngModel)]="model.ApplicantZipCode" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Phone: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantPhone" placeholder="###-###-####" [(ngModel)]="model.ApplicantPhone" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Mobile Phone: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantMobilePhone" placeholder="###-###-####" [(ngModel)]="model.ApplicantMobilePhone" />
            </div>

            <div class="col-6 control-label text-end mb-2">
                <label><strong>Email: </strong></label>
            </div>
            <div class="col-6 text-start mb-2">
                <input type="text" class="form-control" name="ApplicantEmail" placeholder="Email Address" [(ngModel)]="model.ApplicantEmail" />
            </div>
        </div>
    </div>
</form>
