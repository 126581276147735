<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item active" aria-current="page">Report Center</li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">Report Center</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>
</div>
<div class="container">
    <div class="row my-2">
        <div class="col">
            <a class="btn btn-zybach btn-md me-3 float-end" routerLink="/reports/new"><span class="fas fa-plus"></span> New Report Template</a>
            <fresca-clear-grid-filters-button class="float-end me-3" [grid]="reportTemplatesGrid"> </fresca-clear-grid-filters-button>
        </div>
    </div>
    <div class="table-responsive">
        <ag-grid-angular
            #reportTemplatesGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [pagination]="true"
            [paginationPageSize]="100">
        </ag-grid-angular>
    </div>
</div>
