<div class="container" *ngIf="!wellsGeoJson">
    <div class="row mt-5">
        <div class="col text-center">
            <span class="fas fa-spinner loading-spinner"></span>
            <p>Loading...</p>
        </div>
    </div>
</div>

<div class="container navCaretMargin" *ngIf="wellsGeoJson">
    <div class="col mb-3">
        <h2>Sensor Status</h2>
    </div>
    <zybach-sensor-status-map #wellMap [wellsGeoJson]="wellsGeoJson" (onWellSelected)="onMapSelection($event)"></zybach-sensor-status-map>
</div>
<div class="container navCaretMargin">
    <div class="row align-items-end">
        <div class="col">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
        <div class="col-auto ms-auto">
            <fresca-clear-grid-filters-button class="ms-3" [grid]="wellsGrid"></fresca-clear-grid-filters-button>
            <button class="btn btn-secondary btn-md" style="cursor: pointer" (click)="downloadCsv()" ngbTooltip="Download as CSV" [placement]="['bottom', 'right', 'left', 'auto']">
                <i class="fas fa-download"></i>
            </button>
        </div>
    </div>
    <ag-grid-angular
        #wellsGrid
        style="width: 100%; height: 400px"
        class="ag-theme-balham mt-2"
        headerHeight="70"
        [rowData]="redSensors"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowSelection]="'multiple'"
        (selectionChanged)="onSelectionChanged($event)"
        [pagination]="true"
        [paginationPageSize]="100">
    </ag-grid-angular>
</div>
