export const TwinPlatteBoundaryGeoJson = {
    type: "FeatureCollection",
    crs: { type: "name", properties: { name: "EPSG:4326" } },
    features: [
        {
            type: "Feature",
            id: 12,
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [-100.25121578262475, 41.39331582853508],
                        [-100.26535589324286, 41.39335778895844],
                        [-100.2811686321578, 41.393390797664551],
                        [-100.29427095892407, 41.393357963629363],
                        [-100.3185159587573, 41.393293263013483],
                        [-100.33208838135553, 41.393466739908568],
                        [-100.34723361138803, 41.393406236182521],
                        [-100.3664237746025, 41.393448636273646],
                        [-100.38567645449902, 41.393479770136082],
                        [-100.40487674267479, 41.39346076369425],
                        [-100.42428574280295, 41.39347702227014],
                        [-100.44340289533908, 41.393491128208169],
                        [-100.46269725386003, 41.3935013361586],
                        [-100.47806176033916, 41.393493334862676],
                        [-100.50116084159923, 41.393504722287005],
                        [-100.52320710592943, 41.393508415251809],
                        [-100.53974970279639, 41.393518181593834],
                        [-100.55890822875482, 41.393473613460863],
                        [-100.57820258009474, 41.393464410861704],
                        [-100.59743424154715, 41.393436593252922],
                        [-100.61663468851725, 41.393413570811276],
                        [-100.63592939150266, 41.393433912825884],
                        [-100.65523460717002, 41.393458802284798],
                        [-100.67496784186974, 41.393540725943637],
                        [-100.69737880621371, 41.393500981134679],
                        [-100.71452714196558, 41.393591777930318],
                        [-100.73238377631638, 41.393628399839372],
                        [-100.74037899970918, 41.393632066841619],
                        [-100.75410797965314, 41.393684005661292],
                        [-100.77461373405818, 41.393830072788262],
                        [-100.79377326410908, 41.393840500728032],
                        [-100.80940976798804, 41.39387262254828],
                        [-100.82926776384598, 41.393880051274962],
                        [-100.84370766729326, 41.394073131668357],
                        [-100.85648861451139, 41.39414334063747],
                        [-100.86804892950282, 41.39413501522769],
                        [-100.8778587898134, 41.394186021959925],
                        [-100.89092100544271, 41.394234548212623],
                        [-100.90612096154088, 41.394335384132148],
                        [-100.92535515961538, 41.394433162565662],
                        [-100.94470361172714, 41.394495380492437],
                        [-100.94457938241236, 41.408924211910254],
                        [-100.94454871962867, 41.423336543863144],
                        [-100.94458346905567, 41.437726371682601],
                        [-100.94433781280806, 41.460545789724037],
                        [-100.94433114737662, 41.473741113618836],
                        [-100.94423777071489, 41.488153813150475],
                        [-100.94415441534584, 41.495352601602086],
                        [-100.94412329125998, 41.513336302427987],
                        [-100.94417738327977, 41.524246781425759],
                        [-100.9439174676554, 41.556683272021601],
                        [-100.94390691657844, 41.567460752449279],
                        [-100.94383455951964, 41.581896581544598],
                        [-100.94373080925983, 41.592737567829815],
                        [-100.94380387346898, 41.607109336995627],
                        [-100.94354315936062, 41.625141629082265],
                        [-100.94346088022817, 41.639624464906092],
                        [-100.94338768195942, 41.650417973401709],
                        [-100.94321090848948, 41.6648858027663],
                        [-100.94302299432853, 41.690107243072077],
                        [-100.94297031618294, 41.697281935482017],
                        [-100.94281301334523, 41.711663107922924],
                        [-100.94270779348611, 41.72602810491982],
                        [-100.94249876680067, 41.740464542994253],
                        [-100.95861161085044, 41.74080281182377],
                        [-100.98086964615426, 41.740686466907754],
                        [-101.00501766701349, 41.740800566672014],
                        [-101.01940609228213, 41.74074503976663],
                        [-101.03890089152023, 41.740845956666199],
                        [-101.05776638660025, 41.740910103395215],
                        [-101.06499371620808, 41.740663114171063],
                        [-101.07742003930399, 41.741097252077076],
                        [-101.09464288087838, 41.741932083570305],
                        [-101.10739839187622, 41.741975256840796],
                        [-101.11427490967623, 41.742050371822792],
                        [-101.12817510537428, 41.742221483745915],
                        [-101.15519468388422, 41.742252526833461],
                        [-101.1775918765106, 41.74225384868933],
                        [-101.19645702147832, 41.742248241964901],
                        [-101.20645851305503, 41.742403176719016],
                        [-101.2309327679388, 41.742490166322987],
                        [-101.24925353711268, 41.742505284094811],
                        [-101.28236582969323, 41.742668103941476],
                        [-101.30408661717902, 41.74288003763111],
                        [-101.3247152002599, 41.742974829900568],
                        [-101.36757174639101, 41.743440560702091],
                        [-101.38795790552297, 41.743448429901498],
                        [-101.4022971110394, 41.743487069127966],
                        [-101.41865524421371, 41.743342135035974],
                        [-101.48944014309764, 41.742615324055897],
                        [-101.52740377550283, 41.742621613259558],
                        [-101.54611053950494, 41.742513860548563],
                        [-101.56081331132586, 41.742402337627283],
                        [-101.59753518170736, 41.742190393175775],
                        [-101.61846711796183, 41.742204946036395],
                        [-101.65269004006035, 41.742638480084082],
                        [-101.69813740053628, 41.742583278075742],
                        [-101.75852012410139, 41.742464164856379],
                        [-101.77649339032413, 41.742345945558881],
                        [-101.7959399622803, 41.742437525578652],
                        [-101.81904839784768, 41.74227976742241],
                        [-101.8345802787254, 41.742190951975431],
                        [-101.85446198263047, 41.742093045284825],
                        [-101.86992133423503, 41.74203204073757],
                        [-101.90932355453414, 41.74202286734257],
                        [-101.94777470765678, 41.742094805217171],
                        [-101.95133949396858, 41.742120950622663],
                        [-101.98551374972168, 41.742174039575488],
                        [-101.98562155491342, 41.727588384737111],
                        [-101.98545789082843, 41.713117934121911],
                        [-101.98545871935258, 41.698615999963828],
                        [-101.98529458086983, 41.684126391191569],
                        [-101.98524431653362, 41.669615706139354],
                        [-101.98508817780196, 41.655383299136716],
                        [-101.98505010182247, 41.640853243085196],
                        [-101.98486663118476, 41.626554477483708],
                        [-101.98494494576728, 41.612089070543661],
                        [-101.98493338465141, 41.604652228357686],
                        [-101.9847511762086, 41.59122099391832],
                        [-101.98471894904873, 41.583107512346025],
                        [-101.98506343597477, 41.568627852702491],
                        [-101.98499829565421, 41.554040797158194],
                        [-101.98468681622194, 41.535129153391551],
                        [-101.98483937591656, 41.525172177630765],
                        [-101.98484063118428, 41.510679209435125],
                        [-101.98479525890868, 41.496320497397271],
                        [-101.98475996026629, 41.481875762525718],
                        [-101.98476546684172, 41.467106101262537],
                        [-101.98469946160436, 41.452900201140579],
                        [-101.98474142291903, 41.440570790859525],
                        [-101.98456940825842, 41.424965021711394],
                        [-101.98461761160348, 41.409517507006193],
                        [-101.98456916293931, 41.395053734792903],
                        [-102.0034123595936, 41.395098299200527],
                        [-102.02445826413613, 41.395120017854268],
                        [-102.04280942968714, 41.395214468601708],
                        [-102.05526946339484, 41.395211372769396],
                        [-102.05502307988553, 41.380588770837271],
                        [-102.05502226828168, 41.366123843267381],
                        [-102.05522260892016, 41.351617142980906],
                        [-102.05531116499016, 41.337179163643],
                        [-102.05536025439943, 41.322694188621007],
                        [-102.05540601629383, 41.308104360344103],
                        [-102.05548906548377, 41.293494767723942],
                        [-102.05579314700788, 41.279081427395056],
                        [-102.05564630855925, 41.264800113701043],
                        [-102.0556748196727, 41.250467956349681],
                        [-102.05559721515503, 41.235975582335627],
                        [-102.05581235172305, 41.221554216471937],
                        [-102.05591553769607, 41.207208393527772],
                        [-102.05567151900088, 41.192642671280218],
                        [-102.05578888750611, 41.178327895717608],
                        [-102.05574467707305, 41.163691803861298],
                        [-102.05561403159186, 41.149114463466084],
                        [-102.05572683682624, 41.134656694191278],
                        [-102.05585970313211, 41.120036446439315],
                        [-102.05582931516328, 41.105438194169153],
                        [-102.05585591919659, 41.091048685176752],
                        [-102.05558810016723, 41.060072717582507],
                        [-102.05553284452162, 41.047486862741216],
                        [-102.05182887270462, 41.047458127137602],
                        [-102.05185065522292, 41.032887512411627],
                        [-102.05179872470646, 41.018375428056693],
                        [-102.05176088856577, 41.00391076272512],
                        [-102.03309898771393, 41.003178257639064],
                        [-102.01427257515707, 41.003292833709644],
                        [-101.99540312914628, 41.003233418676018],
                        [-101.97623380921102, 41.003242825976869],
                        [-101.95697784789576, 41.003298175847959],
                        [-101.93799890224062, 41.00335510660711],
                        [-101.91915272376687, 41.00321594463832],
                        [-101.90003815256912, 41.003364195019579],
                        [-101.88078155311183, 41.003387627373364],
                        [-101.86171810040892, 41.003118608278115],
                        [-101.84240292745977, 41.002840937335101],
                        [-101.82333411337629, 41.002813507299294],
                        [-101.80486001897712, 41.002897439276758],
                        [-101.78589886979361, 41.003119491697291],
                        [-101.76653884551718, 41.003020384318376],
                        [-101.74764945778436, 41.003101481739868],
                        [-101.72836070590162, 41.003309453271065],
                        [-101.70915079958465, 41.003646467490157],
                        [-101.69023997549429, 41.00338466844385],
                        [-101.68064953070574, 41.003383300107537],
                        [-101.67100274678006, 41.003639386150908],
                        [-101.66673627866952, 41.003644574687073],
                        [-101.65182979833502, 41.003451338601238],
                        [-101.63256390379111, 41.003061178000443],
                        [-101.61325613189688, 41.00300206564345],
                        [-101.59416413265249, 41.003012949661702],
                        [-101.57461576498272, 41.00288392142118],
                        [-101.55544627150115, 41.002803588159601],
                        [-101.53614312597642, 41.00292205606609],
                        [-101.51677214771345, 41.002828404542136],
                        [-101.49782753078541, 41.00321211753915],
                        [-101.47867299441283, 41.003223569852871],
                        [-101.4609214272586, 41.00293736232905],
                        [-101.44162165195061, 41.003201877506818],
                        [-101.42240519397856, 41.0026707575119],
                        [-101.40346539399364, 41.003286626705119],
                        [-101.38429332473015, 41.003644745937606],
                        [-101.36515938873997, 41.004027788611907],
                        [-101.34553085493923, 41.003622133718068],
                        [-101.3262680213096, 41.003237457985954],
                        [-101.30726504364375, 41.003246981983537],
                        [-101.28813153154758, 41.003655237005312],
                        [-101.26899279645919, 41.00380292564661],
                        [-101.25000550838055, 41.003974347336353],
                        [-101.2500052464443, 40.989454358715285],
                        [-101.2492394806674, 40.974370723928203],
                        [-101.2486438804401, 40.95961890485745],
                        [-101.24871680377424, 40.944964635956687],
                        [-101.24861265176554, 40.930255102659444],
                        [-101.24861106401075, 40.915649318145121],
                        [-101.22912114233169, 40.915803980128132],
                        [-101.20994280276277, 40.915818528201591],
                        [-101.19080319038214, 40.915886646650506],
                        [-101.17167678545987, 40.915989549207637],
                        [-101.15263761103562, 40.916050225013784],
                        [-101.13363708770908, 40.91616453728254],
                        [-101.11523299919132, 40.917356678846154],
                        [-101.09585139355315, 40.917249143661202],
                        [-101.07681116642974, 40.917259024997911],
                        [-101.05759624040729, 40.917343614305047],
                        [-101.03869952825667, 40.916888188505105],
                        [-101.02591949097381, 40.916975345513471],
                        [-101.00103599264179, 40.917015483767678],
                        [-100.98200711503567, 40.916923626974203],
                        [-100.96757549773672, 40.916656190296976],
                        [-100.94746402700869, 40.916424752780259],
                        [-100.92967808518976, 40.916218488688415],
                        [-100.92022697203018, 40.916190324169513],
                        [-100.9056964292962, 40.916001654388367],
                        [-100.88750108962209, 40.916163861944966],
                        [-100.86813436262167, 40.916131894681108],
                        [-100.84897031631399, 40.916219058328672],
                        [-100.83015855646049, 40.91633856828377],
                        [-100.81113239471316, 40.916399298703169],
                        [-100.79218159595453, 40.916456328991472],
                        [-100.77299144497336, 40.916464165479852],
                        [-100.75388925385077, 40.916468189981146],
                        [-100.73525115998635, 40.916389753591588],
                        [-100.71592519089053, 40.916608121084117],
                        [-100.69721363269042, 40.916705055693448],
                        [-100.68206920530916, 40.916615563621669],
                        [-100.65902207552827, 40.916722558713261],
                        [-100.63891741193765, 40.915721665371642],
                        [-100.62061275303024, 40.914964833794208],
                        [-100.6139887763719, 40.914829116537419],
                        [-100.60019035298239, 40.914816818638414],
                        [-100.58333928935429, 40.914923379885501],
                        [-100.56459847681019, 40.914483910939808],
                        [-100.55223307808373, 40.914506365452588],
                        [-100.54104928363124, 40.91456953939727],
                        [-100.52679977701739, 40.914703026232694],
                        [-100.50801306093501, 40.914759715363786],
                        [-100.5005460982407, 40.914449566411513],
                        [-100.48980930888716, 40.913790775689293],
                        [-100.47108202656698, 40.913335875692212],
                        [-100.4524258569766, 40.914040636863191],
                        [-100.45207669728917, 40.899847461524203],
                        [-100.45205360982474, 40.885557665137036],
                        [-100.45203739164059, 40.87591038787717],
                        [-100.45196841063165, 40.864070745041985],
                        [-100.45216060201093, 40.842754325464441],
                        [-100.45172540774766, 40.828761702550665],
                        [-100.43281394091666, 40.829006777391186],
                        [-100.42011430906879, 40.829187820368475],
                        [-100.39565510901758, 40.829380331615553],
                        [-100.37700593586773, 40.829462694987932],
                        [-100.36888656129032, 40.829575094100257],
                        [-100.35528278386867, 40.829704164659788],
                        [-100.3391051001083, 40.829629583841118],
                        [-100.33885586413462, 40.815378676692539],
                        [-100.33804250169827, 40.786553702582808],
                        [-100.31289646362166, 40.786701914156794],
                        [-100.29986614415002, 40.786889536531419],
                        [-100.28081418448994, 40.786757040957305],
                        [-100.28109165136244, 40.772457100644615],
                        [-100.28109303365065, 40.758119719202135],
                        [-100.28103105396249, 40.743620448052262],
                        [-100.26177920453735, 40.743637813933873],
                        [-100.24261490534505, 40.743604068299476],
                        [-100.22341309055081, 40.743586231461663],
                        [-100.22370036005962, 40.758027866048955],
                        [-100.22372423965656, 40.772412832635538],
                        [-100.22378620872944, 40.78693119367211],
                        [-100.22379760463127, 40.801325736194876],
                        [-100.22384644389862, 40.804614466411806],
                        [-100.22464762063778, 40.833459241361645],
                        [-100.22463362772628, 40.858721298762511],
                        [-100.22441839632974, 40.869217385296295],
                        [-100.2244804102483, 40.887348738796341],
                        [-100.22447986794737, 40.901876803257693],
                        [-100.22446610777577, 40.916214237697773],
                        [-100.22451871771656, 40.924240794383017],
                        [-100.22442380876829, 40.944250436051291],
                        [-100.22425324261464, 40.9531925861167],
                        [-100.22429692223911, 40.973306813056631],
                        [-100.22432156619497, 40.987853890103978],
                        [-100.2243339309596, 41.002486783030825],
                        [-100.22427058805553, 41.017062623119465],
                        [-100.22423233085674, 41.031619342333592],
                        [-100.22423349696292, 41.04666215284935],
                        [-100.23822279335407, 41.04660522995745],
                        [-100.23816090877972, 41.053821714506597],
                        [-100.23795243977538, 41.065757253685703],
                        [-100.23787449762131, 41.07549996478231],
                        [-100.23768669869128, 41.082754834322969],
                        [-100.23763876398969, 41.104241902869241],
                        [-100.23770269778935, 41.11142950821246],
                        [-100.23762973942101, 41.132973793193948],
                        [-100.23760672341655, 41.147454151019453],
                        [-100.23760913223622, 41.161991639690704],
                        [-100.23648761639414, 41.17624545847513],
                        [-100.23584597702722, 41.190727048013692],
                        [-100.23549419377008, 41.205160357922907],
                        [-100.23522522732645, 41.219591528025951],
                        [-100.23501293145112, 41.234095466752514],
                        [-100.23500212576442, 41.248589461618828],
                        [-100.23452465949165, 41.262998797312143],
                        [-100.23432453708106, 41.277464606521825],
                        [-100.23460352447776, 41.29193892441851],
                        [-100.2348450041037, 41.306470357360681],
                        [-100.23619983318439, 41.321855415028537],
                        [-100.23586099775687, 41.336292886494952],
                        [-100.23588056821755, 41.345185049143403],
                        [-100.23626617433585, 41.361038031086551],
                        [-100.2365446565606, 41.371679535730159],
                        [-100.23653247876143, 41.378755250068679],
                        [-100.23609273255622, 41.393287926708368],
                        [-100.25121578262475, 41.39331582853508],
                    ],
                ],
            },
            properties: { OBJECTID: 12, NRD_Name: "Twin Platte", NRD_Name_ALLCAP: "TWIN PLATTE", NRD_Num: 17, Shape__Area: 119106067893.38174, Shape__Length: 1733875.4743723273 },
        },
    ],
};
