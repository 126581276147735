<nav aria-label="breadcrumb" *ngIf="wellRegistrationID">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/well-map">Wells</a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/wells/{{ wellID }}">{{ wellRegistrationID }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Edit Registration ID for Well {{ wellRegistrationID }}</li>
    </ol>
</nav>

<div class="container" *ngIf="wellID">
    <app-alert-display></app-alert-display>

    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Edit Registration ID for Well {{ wellRegistrationID }}</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="row mt-2">
        <div class="card ms-4">
            <div class="card-header">Well Registration ID</div>
            <div class="card-body">
                <form (ngSubmit)="onSubmit(editWellRegistrationIDForm)" #editWellRegistrationIDForm="ngForm" class="container">
                    <div class="row col-12 mt-4 text-center">
                        <div class="col-6 control-label text-end mb-2">
                            <label
                                ><strong>
                                    <field-definition [fieldDefinitionType]="'WellRegistrationNumber'"> </field-definition> </strong
                            ></label>
                        </div>
                        <div class="col-6 text-start mb-2">
                            <input type="text" class="form-control" name="WellRegistrationID" [(ngModel)]="newWellRegistrationIDDto.WellRegistrationID" />
                        </div>

                        <div class="col-12 text-end mt-3 me-3">
                            <button type="submit" class="btn btn-zybach me-1">
                                <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                                Save
                            </button>
                            <a class="btn btn-secondary ms-1" routerLink="/wells/{{ wellID }}"> Cancel </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
