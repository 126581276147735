<label class="rowHeader">Registration #:</label> <a routerLink="/wells/{{ wellID }}">{{ wellRegistrationID }}</a
><br />

<label class="rowHeader">AgHub Registered User:</label>{{ AgHubRegisteredUser }}
<br />
<label class="rowHeader">Field Name:</label>{{ fieldName }}

<div *ngFor="let sensor of sensors">
    <hr class="mt-0" />
    <label class="rowHeader">{{ sensor.SensorTypeName }} {{ sensor.SensorName ? "(" + sensor.SensorName + ")" : "" }}</label
    ><br />

    <label class="rowHeader">Last Message Age:</label>
    <span *ngIf="sensor.LastMessageAgeInHours != null; else notAvailable">{{ sensor.LastMessageAgeInHours }} hours</span><br />

    <label class="rowHeader">Last Voltage Reading:</label>
    <span *ngIf="sensor.LastVoltageReading != null; else notAvailable">{{ sensor.LastVoltageReading | number: "1.0-0" }} mV</span><br />

    <ng-container *ngIf="sensor.MostRecentSupportTicketID && sensor.MostRecentSupportTicketTitle">
        <label class="rowHeader">Active Support Ticket:</label>
        <span *ngIf="sensor.LastVoltageReading != null; else notAvailable">
            <a routerLink="/support-tickets/{{ sensor.MostRecentSupportTicketID }}">{{ sensor.MostRecentSupportTicketTitle }}</a>
        </span>
    </ng-container>
</div>

<ng-template #notAvailable>N/A</ng-template>
