<div class="modal-header pt-3">
    <h5 class="modal-title no-wrap">{{ options.title }}</h5>
</div>
<div class="modal-body">
    <div [innerHtml]="this.sanitizer.bypassSecurityTrustHtml(options.message)"></div>
</div>
<div class="modal-footer">
    <button class="btn btn-md {{ options.buttonClassYes }}" (click)="yes()">{{ options.buttonTextYes }}</button>
    <button class="btn btn-outline-primary btn-md right" (click)="no()">{{ options.buttonTextNo }}</button>
</div>
