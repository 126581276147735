<div class="container navCaretMargin">
    <div class="row">
        <div class="col-12">
            <h2 class="d-inline-block">Manager Dashboard</h2>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12 col-md-6 d-flex align-items-center">Water Usage Maps</div>
                        <div class="col-12 col-md-6 text-end">
                            <div class="float-end ps-2">
                                <zybach-water-year-select
                                    [(selectedYear)]="yearToDisplay"
                                    (selectedYearChange)="updateAnnualData()"
                                    [(allYearsSelected)]="allYearsSelected"
                                    (allYearsSelectedChange)="updateAnnualData()">
                                </zybach-water-year-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div id="{{ mapID }}" style="height: 500px" [class]="{ 'col-9': selectedStreamflowZone, 'col': !selectedStreamflowZone }"></div>
                        <div class="col-3" *ngIf="selectedStreamflowZone">
                            <dl>
                                <dt>Stream Flow Zone Name:</dt>
                                <dd>{{ selectedStreamflowZone.StreamFlowZoneName }}</dd>
                                <dt>Acres:</dt>
                                <dd>{{ getAcreage(selectedStreamflowZone) | number: "1.0-2" }}</dd>
                                <dt>Average Pumping Depth:</dt>
                                <dd>{{ getPumpingDepth(selectedStreamflowZone) | number: "1.0-2" }} ac-in/ac</dd>
                                <dt>Total Irrigated Acres:</dt>
                                <dd>{{ getTotalIrrigatedAcres(selectedStreamflowZone) | number: "1.0-2" }} ac</dd>
                                <dt>Total Pumped Volume:</dt>
                                <dd>{{ getTotalPumpedVolume(selectedStreamflowZone) | number: "1.0-2" }} ac-in</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">District-Wide Statistics</div>
                <div class="card-body">
                    <div class="row" *ngIf="loadingDistrictStatistics">
                        <div class="col text-center">
                            <span class="fas fa-spinner loading-spinner"></span>
                            <p>Loading...</p>
                        </div>
                    </div>
                    <div class="row" *ngIf="!loadingDistrictStatistics">
                        <div class="col-12 col-sm-6 label"><strong>Wells with Flow Meters: </strong></div>
                        <div class="col-12 col-sm-6">{{ districtStatistics.NumberOfFlowMeters }}</div>
                        <div class="col-12 col-sm-6 label"><strong>Wells with Continuity Meters: </strong></div>
                        <div class="col-12 col-sm-6">{{ districtStatistics.NumberOfContinuityMeters }}</div>
                        <div class="col-12 col-sm-6 label"><strong>AgHub Wells with Connected Electrical Meters: </strong></div>
                        <div class="col-12 col-sm-6">{{ districtStatistics.NumberOfAgHubWellsWithConnectedElectricalMeters }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MP 9/22/21 Temporarily removing, but will likely reintroduce later -->
        <!-- <div class="col-12 col-md-6">
            <div class="card">
                <div class="card-header">
                    Usage Charts
                </div>
                <div class="card-body">
                    <p class="text-italic">Under construction</p>
                </div>
            </div>
        </div> -->
    </div>
</div>
