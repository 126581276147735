<nav aria-label="breadcrumb">
    <ol class="breadcrumb p-3">
        <li class="breadcrumb-item">
            <a routerLink="/sensors">Sensors</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Add Sensor</li>
    </ol>
</nav>

<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row mt-2">
        <div class="col-12">
            <h2 class="d-inline-block">Add Sensor</h2>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12">
            <p>Add a sensor using the form below</p>
        </div>
    </div>

    <form #addSensorForm="ngForm" (ngSubmit)="onSubmit(sensorUpsertFormComponent)" class="container">
        <zybach-sensor-upsert-form #sensorUpsertFormComponent [sensor]="sensor" [isNewSensor]="true"> </zybach-sensor-upsert-form>

        <!-- Form Buttons -->
        <div class="col-12 text-end mt-3 me-3">
            <div class="col-sm text-start"><i class="fas fa-bolt"></i> Required field</div>
            <div class="float-end">
                <button type="submit" class="btn btn-primary me-1" [disabled]="!isFormValid(sensorUpsertFormComponent)">
                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                    Save
                </button>
                <a class="btn btn-secondary ms-1" routerLink="..">Cancel</a>
            </div>
        </div>
    </form>
</div>
