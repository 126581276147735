<div class="container mt-5" *ngIf="!wellGroups">
    <div class="row">
        <div class="col text-center">
            <span class="fas fa-spinner loading-spinner"></span>
            <p>Loading...</p>
        </div>
    </div>
</div>

<div class="container navCaretMargin" *ngIf="wellGroups">
    <div clsas="row">
        <div class="col">
            <h2 class="d-inline-block">Water Level Explorer</h2>
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-xl-6 mb-3">
            <div class="card">
                <div class="card-header">Water Level Summary</div>
                <div class="card-body m-0" *ngIf="selectedWellGroup; else noWellSelected">
                    <div class="row mb-4">
                        <div class="col-sm-6 label">
                            <strong><field-definition fieldDefinitionType="WellRegistrationNumber" labelOverride="Well Registration Number"></field-definition>: </strong>
                        </div>
                        <div class="col-sm-6">
                            <a *ngIf="isAuthenticated()" routerLink="/wells/{{ selectedWellGroup?.PrimaryWell.WellID }}">{{ selectedWellGroup?.PrimaryWell.WellRegistrationID }}</a>
                            <span *ngIf="!isAuthenticated()">{{ selectedWellGroup?.PrimaryWell.WellRegistrationID }}</span>
                        </div>

                        <div class="col-sm-6 label"><strong>Legal Description: </strong></div>
                        <div class="col-sm-6">{{ selectedWellGroup?.PrimaryWell.TownshipRangeSection }}</div>

                        <div class="col-sm-6 label">
                            <strong><field-definition fieldDefinitionType="SensorLastReadingDate"></field-definition>: </strong>
                        </div>
                        <div class="col-sm-6">{{ sensorChartData?.LastReadingDate | date: "shortDate" }}</div>
                        <div class="col-sm-6 label">
                            <strong
                                ><field-definition fieldDefinitionType="LatestWaterLevelInspectionDate" labelOverride="Last Water Level Inspection Date"></field-definition>:
                            </strong>
                        </div>
                        <div class="col-sm-6">{{ waterLevelInspectionChartData?.LastInspectionDate | date: "shortDate" }}</div>
                    </div>

                    <nav ngbNav #nav="ngbNav" [destroyOnHide]="false" [animation]="false" (shown)="updateChart($event)" class="nav-tabs nav-fill">
                        <ng-container ngbNavItem>
                            <a ngbNavLink><h5>Daily Measurements</h5></a>
                            <ng-template ngbNavContent id="sensorChartTab">
                                <div>
                                    <zybach-sensor-chart [(sensorChartData)]="sensorChartData"></zybach-sensor-chart>
                                    <div class="mt-2">
                                        <custom-rich-text [customRichTextTypeID]="disclaimerRichTextTypeID"></custom-rich-text>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container ngbNavItem>
                            <a ngbNavLink><h5>Spring/Fall Measurements</h5></a>
                            <ng-template ngbNavContent #waterLevelInspectionTab>
                                <div class="row mb-2">
                                    <div class="col-auto">
                                        <button (click)="setFullRange($event)" class="btn btn-zybach">Full Range</button>
                                    </div>
                                    <div class="col-auto ms-auto">
                                        <a class="btn btn btn-zybach float-end" (click)="exportWaterLevelInspectionChartData()"
                                            ><span class="fas fa-download"></span> Download CSV</a
                                        >
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-6">
                                        <span class="fw-bold mt-1 mx-2">Start Date:</span>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                name="d1"
                                                ngbDatepicker
                                                #d1="ngbDatepicker"
                                                placeholder="yyyy-mm-dd"
                                                (ngModelChange)="onStartDateChanged($event)"
                                                [(ngModel)]="startDate" />
                                            <button class="btn btn-zybach" (click)="d1.toggle()" type="button"><span class="far fa-calendar me-2 col-1"> </span></button>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <span class="fw-bold mt-1 mx-2">End Date:</span>
                                        <div class="input-group">
                                            <input
                                                class="form-control"
                                                name="d2"
                                                ngbDatepicker
                                                #d2="ngbDatepicker"
                                                placeholder="yyyy-mm-dd"
                                                (ngModelChange)="onEndDateChanged($event)"
                                                [(ngModel)]="endDate" />
                                            <button class="btn btn-zybach" (click)="d2.toggle()" type="button"><span class="far fa-calendar me-2 col-1"> </span></button>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="hasWaterLevelInspectionChartData; else noWaterLevelSpecDiv" [id]="waterLevelInspectionChartID" style="width: 100%; height: 340px">
                                    <span class="fa fa-spinner loading-spinner"></span> Loading
                                </div>
                                <ng-template #noWaterLevelSpecDiv><em>No Water Level Chart data to show</em></ng-template>
                            </ng-template>
                        </ng-container>
                    </nav>

                    <div [ngbNavOutlet]="nav" class="mt-4"></div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <zybach-well-water-level-map #wellMap [wellsGeoJson]="wellsGeoJson" (onWellSelected)="onMapSelection($event)"> </zybach-well-water-level-map>
        </div>
    </div>
</div>

<ng-template #noWellSelected>
    <div class="card-body">
        <p>Click on a well on the map to view water level information.</p>
    </div>
</ng-template>
