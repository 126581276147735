/**
 * Zybach.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContinuityMeterStatusDto } from '././continuity-meter-status-dto';

export class SensorSimpleDto { 
    SensorID?: number;
    SensorTypeID?: number;
    SensorModelID?: number;
    WellID?: number;
    ContinuityMeterStatusID?: number;
    PhotoBlobID?: number;
    SensorName?: string;
    InstallationDate?: string;
    InstallationInstallerInitials?: string;
    InstallationOrganization?: string;
    InstallationComments?: string;
    WellDepth?: number;
    InstallDepth?: number;
    CableLength?: number;
    WaterLevel?: number;
    CalibrationFactor?: number;
    FlowMeterReading?: number;
    PipeDiameterID?: number;
    IsActive?: boolean;
    InGeoOptix?: boolean;
    RetirementDate?: string;
    ContinuityMeterStatusLastUpdated?: string;
    SnoozeStartDate?: string;
    CreateDate?: string;
    CreateUserID?: number;
    LastUpdateDate?: string;
    UpdateUserID?: number;
    WellRegistrationID?: string;
    SensorTypeName?: string;
    SensorModelName?: string;
    LastMessageAgeInHours?: number;
    LastVoltageReading?: number;
    LastVoltageReadingDate?: string;
    FirstReadingDate?: string;
    LastReadingDate?: string;
    WellPageNumber?: number;
    WellOwnerName?: string;
    WellTownshipRangeSection?: string;
    MostRecentSupportTicketID?: number;
    MostRecentSupportTicketTitle?: string;
    ContinuityMeterStatus?: ContinuityMeterStatusDto;
    LastOnReadingDate?: string;
    LastOffReadingDate?: string;
    Latitude?: number;
    Longitude?: number;
    PipeDiameterDisplayName?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
