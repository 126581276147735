<div class="form-group form-inline mt-0 mb-0 d-flex">
    {{ selectYearLabel }}
    <a href="#" class="nav-link water-year-dropdown dropdown-toggle fw-bold" role="button" data-bs-toggle="dropdown">
        {{ selectedYear || "All Years" }}
    </a>
    <div class="dropdown-menu">
        <ng-template ngFor let-year [ngForOf]="years">
            <a class="dropdown-item" (click)="selectedYear = year; allYearsSelected = false">
                {{ year }}
            </a>
        </ng-template>
        <a *ngIf="displayAllYearsOption" class="dropdown-item" (click)="selectedYear = null; allYearsSelected = true">All Years</a>
    </div>
</div>
