<form #inspectionUpsertForm="ngForm">
    <div class="row col-12 mt-4">
        <div class="row col-12">
            <div class="row col-6">
                <div class="col-6 control-label text-end mb-2">
                    <label class="required"><strong>Date Inspected: </strong></label>
                </div>
                <div class="col-6 text-start mb-2 form-group">
                    <div class="input-group">
                        <input class="form-control" name="dp1" placeholder="yyyy-mm-dd" required [(ngModel)]="inspection.InspectionDate" ngbDatepicker #d1="ngbDatepicker" />
                        <div class="input-group-append">
                            <button class="btn btn-zybach" (click)="d1.toggle()" type="button"><span class="far fa-calendar me-2 col-1"> </span></button>
                        </div>
                    </div>
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label class="required"
                        ><strong> <field-definition [fieldDefinitionType]="'WellRegistrationNumber'"> </field-definition>: </strong></label
                    >
                </div>
                <div class="col-6 text-start mb-2">
                    <input
                        type="text"
                        class="form-control"
                        name="WellRegistrationID"
                        required
                        [(ngModel)]="inspection.WellRegistrationID"
                        [ngbTypeahead]="searchApi"
                        placeholder="Search by Reg #" />
                    <small class="form-text text-muted font-italic" *ngIf="searchFailed">Suggestions could not be loaded.</small>
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label class="required"><strong>Inspector: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <ng-select
                        [(ngModel)]="inspection.InspectorUserID"
                        required
                        [items]="users"
                        bindLabel="FullNameLastFirst"
                        bindValue="UserID"
                        placeholder="Choose One"
                        name="InspectorUserID">
                    </ng-select>
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label class="required"><strong>Inspection Type: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <ng-select
                        [(ngModel)]="inspection.WaterQualityInspectionTypeID"
                        required
                        [items]="inspectionTypes"
                        bindLabel="WaterQualityInspectionTypeDisplayName"
                        bindValue="WaterQualityInspectionTypeID"
                        placeholder="Choose One"
                        name="WaterQualityInspectionTypeID">
                    </ng-select>
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Nickname: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="text" class="form-control" name="Nickname" [(ngModel)]="inspection.InspectionNickname" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Temperature: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="Temperature" [(ngModel)]="inspection.Temperature" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>pH: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="PH" [(ngModel)]="inspection.PH" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Conductivity: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="Conductivity" [(ngModel)]="inspection.Conductivity" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Field Alkalinity: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="FieldAlkilinity" [(ngModel)]="inspection.FieldAlkilinity" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Field Nitrates: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="FieldNitrates" [(ngModel)]="inspection.FieldNitrates" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Lab Nitrates: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="LabNitrates" [(ngModel)]="inspection.LabNitrates" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Salinity: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <input type="number" step="0.0001" class="form-control" name="Salinity" [(ngModel)]="inspection.Salinity" />
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Crop Type: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <ng-select
                        [(ngModel)]="inspection.CropTypeID"
                        [items]="cropTypes"
                        bindLabel="CropTypeDisplayName"
                        bindValue="CropTypeID"
                        placeholder="Choose One"
                        name="CropTypeID">
                    </ng-select>
                </div>

                <div class="col-6 control-label text-end mb-2">
                    <label><strong>Inspection Notes: </strong></label>
                </div>
                <div class="col-6 text-start mb-2">
                    <textarea class="form-control" name="InspectionNotes" placeholder="Notes" [(ngModel)]="inspection.InspectionNotes"> </textarea>
                </div>
            </div>

            <div class="row col-6">
                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>MV: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="MV" [(ngModel)]="inspection.MV" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>Na: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="Sodium" [(ngModel)]="inspection.Sodium" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>Ca: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="Calcium" [(ngModel)]="inspection.Calcium" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>Mg: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="Magnesium" [(ngModel)]="inspection.Magnesium" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>K: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="Potassium" [(ngModel)]="inspection.Potassium" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label
                            ><strong>HCO<sub>3</sub><sup>-</sup>: </strong></label
                        >
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="HydrogenCarbonate" [(ngModel)]="inspection.HydrogenCarbonate" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>CaCO<sub>3</sub>: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="CalciumCarbonate" [(ngModel)]="inspection.CalciumCarbonate" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label
                            ><strong>SO<sub>4</sub><sup>2-</sup>: </strong></label
                        >
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="Sulfate" [(ngModel)]="inspection.Sulfate" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>Cl: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="Chloride" [(ngModel)]="inspection.Chloride" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>SiO<sub>2</sub>: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="SiliconDioxide" [(ngModel)]="inspection.SiliconDioxide" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>Pre Water Level: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="PreWaterLevel" [(ngModel)]="inspection.PreWaterLevel" />
                    </div>
                </div>

                <div class="row col-12">
                    <div class="col-6 control-label text-end mb-2">
                        <label><strong>Post Water Level: </strong></label>
                    </div>
                    <div class="col-6 text-start mb-2">
                        <input type="number" step="0.0001" class="form-control" name="PostWaterLevel" [(ngModel)]="inspection.PostWaterLevel" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
